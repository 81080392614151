import React from "react";

import "../styles/centered-box.less";

import { Layout, Row, Col } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

//const sexeForm = Form.create({ name: "homeform" })(TemplateHomeForm)

export default function NousContacter() {
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Une question ? Une réclamation ?</h2>
            <Row>
              <Col xs={24} style={{ textAlign: "center" }}>
                <p>
                  On est là pour vous! On sera ravi de vous répondre sur{" "}
                  <a href="mailto:contact@patinette.io">contact@patinette.io</a>{" "}
                  :)
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
